import { LogLevel } from "@azure/msal-browser";

/**
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
export const msalConfig = {
    auth: {
        clientId: "047b521a-ed4d-44ac-87ce-7bdbb271e972", 
        authority: "https://login.microsoftonline.com/ferroundpartner.onmicrosoft.com", 
        //redirectUri: "http://localhost:3000/",
        //postLogoutRedirectUri: "http://localhost:3000/",
        redirectUri: "https://fp.baukg.app/",
        postLogoutRedirectUri: "https://www.ferro-partner.at",
        navigateToLoginRequestUrl: false, 
    },
    cache: {
        //cacheLocation: "sessionStorage",
        cacheLocation: "localStorage",
        //storeAuthStateInCookie: false,
        storeAuthStateInCookie: true,
    },
    system: {	
        loggerOptions: {	
            loggerCallback: (level, message, containsPii) => {	
                if (containsPii) {		
                    return;		
                }		
                switch (level) {		
                    case LogLevel.Error:		
                        console.error(message);		
                        return;		
                    case LogLevel.Info:		
                        console.info(message);		
                        return;		
                    case LogLevel.Verbose:		
                        console.debug(message);		
                        return;		
                    case LogLevel.Warning:		
                        console.warn(message);		
                        return;
                    default:
                        return;
                }	
            }	
        }	
    }
};

/**
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes: ["User.Read"]
};